/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */

import { differenceWith, isEqual, omit, pick, pickBy } from 'lodash'

import { fetchProductRequest, patchProductRequest } from '@/request/globalApi/requests/productRequests'
import {
  deleteHelicopterAvailabilityRequest,
  deleteProductBaseRequest,
  deleteProductDepositZoneRequest,
  deleteProductDestinationDZRequest,
  deleteProductDestinationRequest,
  deleteProductHelicopterByIdRequest,
  deleteProductNegotiatedRateByIdRequest,
  deleteProductRegularLineRequest,
  fetchHelicopterAvailabilitiesRequest,
  fetchHelicopterAvailabilityRequest,
  fetchProductBasesRequest,
  fetchProductFareManagementRequest,
  fetchProductHelicoptersRequest,
  fetchProductNegotiatedRateByIdRequest,
  fetchProductNegotiatedRatesRequest,
  fetchProductPlainRequest,
  fetchRegularLineRequest,
  patchDestinationDZRequest,
  patchDestinationRequest,
  patchFareManagementRequest,
  patchHelicopterAvailabilityRequest,
  patchProductBaseRequest,
  patchProductDepositZoneRequest,
  patchProductHelicopterRequest,
  patchProductNegotiatedRateRequest,
  patchProductPlainRequest,
  patchRegularLineRequest,
  postDestinationDZRequest,
  postDestinationRequest,
  postHelicopterAvailabilityRequest,
  postProductBaseRequest,
  postProductDepositZoneRequest,
  postProductHelicopterRequest,
  postProductNegotiatedRateRequest,
  postRegularLineRequest,
} from '@/request/globalApi/requests/productHelicopterRequests'
import store from '@/store/store'
import { fetchTripStepEventRegularFlightHelicopterRequest } from '@/request/globalApi/requests/tripStepEventRequests'
import BaseModel from './_Base'

/**
 * Product Helicopter model
 * @link https://vuemc.io/#basic-usage
 * @link https://vuemc.io/#model-data-access-active ( .sync(), .reset() etc )
 * @example use in /src/views/product/ProductWizard.vue
 */
class ProductHelicopter extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      name: {
        // TODO: dynamic lang init
        fr: '',
        en: '',
      },
      baseReference: '',
      typology: 'helicopter',
      state: '',
      timezone: '',
      vatRate: 0,
      active: false,
      visible: true,
      defaultLang: {
        code: store.state.auth.defaultLang,
      },
      mainCategory: {
        id: null,
        name: {
          // TODO: dynamic lang init
          fr: '',
          en: '',
        },
      },
      mainVariant: {
        id: null,
        attrs: [],
        // TODO: dynamic lang init
        description: { en: '', fr: '' },
        files: [],
        visibilityEndAt: '',
        visibilityStartAt: '',
        packing: {},
        visible: true,
        prices: [],
        priceRanges: [],
        priceRangesFull: [],
        pricingType: '',
        negotiatedRates: [],
        vatRate: 0,
        priceOnRegularLine: false,
        regularLines: [],
      },
      conditions: [],
      negotiatedRates: [],
      helicopters: [],
      availabilitiesHours: [],
      bases: [],
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      name: Object,
      baseReference: String,
      typology: String,
      state: String,
      timezone: String,
      active: Boolean,
      defaultLang: Object,
      mainCategory: Object,
      mainVariant: Object,
      conditions: conditions => conditions || [],
      negotiatedRates: negotiatedRates => negotiatedRates || [],
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
   * GET /product/:id
   */
  async fetch(id = this.id) {
    await this.checkArgs(id)

    await fetchProductRequest(id).then(({ data }) => {
      const prod = {
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.product.mainVariant },
      }
      this.set(prod)
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit a product -> PATCH /product/:id
   */
  async patch(productId = this.id) {
    const payload = {
      ...pick(this, 'name', 'baseReference', 'timezone', 'visible'),
      mainCategoryId: this.mainCategory.id,
      defaultLangCode: this.defaultLang.code,
    }
    await patchProductRequest(productId, payload)
  }

  /**
   * GET /product/:id/additional-informations/:variantId
   */
  async fetchPlain(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductPlainRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: {
          ...this.mainVariant,
          ...omit(data.variant, 'variantAttributes'),
          // ? Trick api, it return an array if no description, or object if has got description
          description: data.variant.description.length !== undefined
            ? {}
            : data.variant.description,
          attrs: data.variant.variantAttributes.map(attr => {
            const normalizeAttr = {
              ...attr,
              type: attr.hasOwnProperty('selectValue')
                ? 'select'
                : attr.hasOwnProperty('choiceValues')
                  ? 'choice'
                  // Delete 'inputValue' after update api
                  : (attr.hasOwnProperty('inputValue') || attr.hasOwnProperty('inputValues'))
                    ? 'input'
                    : undefined,
            }
            // ? NOTE: delete this condition after modification api (s)
            if (normalizeAttr.hasOwnProperty('inputValue')) {
              normalizeAttr.inputValues = normalizeAttr.inputValue
              delete normalizeAttr.inputValue
            }
            return normalizeAttr
          }),
        },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit the mainVariant of product -> PATCH /product/:id/main-information/:variant_id
   */
  async patchPlain(productId = this.id, variantId = this.mainVariant.id) {
    const payload = {
      description: pickBy(this.mainVariant.description, lang => !!lang),
      variantAttributes: this.mainVariant.attrs.map(attr => {
        const values = attr.type === 'select'
          ? { selectValueId: attr.selectValue.id }
          : attr.type === 'choice'
            ? { choiceValueIds: attr.choiceValues.map(choice => choice.id) }
            : { inputValues: attr.inputValues }
        return {
          id: attr.id,
          attributeId: attr.attribute.id,
          type: attr.type,
          ...values,
        }
      }),
      visibilityStartAt: this.mainVariant.visibilityStartAt,
      visibilityEndAt: this.mainVariant.visibilityEndAt,
    }
    const listToDelete = differenceWith(
      this._reference.mainVariant.files,
      this.mainVariant.files,
      isEqual,
    )
    const listToCreate = differenceWith(
      this.mainVariant.files,
      this._reference.mainVariant.files,
      isEqual,
    )

    const posAlreadyExist = this.mainVariant.files.map(file => file.position)
      // Impossible to replace a position in same request
      .concat(listToDelete.map(file => file.position))
    let position = 0

    payload.deletedFileIds = listToDelete.map(file => file.id)
    payload.files = listToCreate.map(file => {
      // Prevent all positions who already exists
      position += 1
      while (posAlreadyExist.includes(position)) {
        position += 1
      }
      return { ...file, position }
    })

    await patchProductPlainRequest({ productId, variantId }, payload)
    this.sync()
  }

  /**
   * GET /helicopter-product/:id/helicopter-variant/:variantId/helicopter-management
   */
  async fetchHelicopters(params = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductHelicoptersRequest(id, variantId, params).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, id: variantId },
        helicopters: data.items,
      })
      this.sync()
      return omit(data, 'product', 'items')
    }).catch(this.catchError)
  }

  /**
   * POST /helicopter-product/:id/helicopter-variant/:variantId/helicopter-management
   */
  async postHelicopter(formData, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      organizationHelicopterId: formData.organizationHelicopter.id,
      active: formData.active,
    }
    await postProductHelicopterRequest(id, variantId, payload)
    this.set({ helicopters: formData })
    this.sync()
  }

  /**
   * PATCH /helicopter-product/:id/helicopter-variant/:variantId/helicopter-management/:organizationHelicopterId
   */
  async patchHelicopter(formData, id = this.id, variantId = this.mainVariant.id) {
    return patchProductHelicopterRequest(id, variantId, formData)
      .then(() => { this.sync() })
      .catch(this.catchError)
  }

  /**
   * DELETE /helicopter-product/:id/helicopter-variant/:variantId/helicopter-management/:organizationHelicopterId
   */
  async deleteHelicopter(organizationHelicopterId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductHelicopterByIdRequest(id, variantId, organizationHelicopterId)
  }

  /**
   * GET /helicopter-product/:id/helicopter-variant/:variantId/general-fare-management
   */
  async fetchFareManagement(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductFareManagementRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * PATCH /helicopter-product/:id/helicopter-variant/:variantId/general-fare-management
   */
  async patchFareManagement(id = this.id, variantId = this.mainVariant.id) {
    const payload = pick(this.mainVariant, 'visible', 'priceOnRegularLine')
    return patchFareManagementRequest(id, variantId, payload)
      .then(() => { this.sync() })
      .catch(this.catchError)
  }

  /**
   * GET /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId/
   */
  async fetchRegularLines(regularLineId, queryParams = { showExtendedData: true }, id = this.id, variantId = this.mainVariant.id) {
    return fetchRegularLineRequest(id, variantId, regularLineId, queryParams)
      .then(({ data }) => {
        const regularLine = this.mainVariant.regularLines.find(rl => rl.id === regularLineId)
        this.$set(regularLine, 'destinations', data.destinationAddressPrices)
        this.sync()
      })
      .catch(this.catchError)
  }

  /**
   * POST /helicopter-product/:id/helicopter-variant/:variantId/regular-line
   */
  async postRegularLine(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    return postRegularLineRequest(id, variantId, formData)
      .then(({ data }) => {
        this.sync()
        return data
      })
      .catch(this.catchError)
  }

  /**
   * PATCH /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId
   */
  async patchRegularLine(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...formData,
      ...pick(formData.address, 'postalCode', 'city', 'address', 'countryCode'),
    }
    return patchRegularLineRequest(id, variantId, payload)
      .then(() => { this.sync() })
      .catch(this.catchError)
  }

  /**
   * DELETE /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId
   */
  async deleteRegularLine(regularLineId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductRegularLineRequest(id, variantId, regularLineId).then(() => {
      this.mainVariant.regularLines = this.mainVariant.regularLines.filter(rl => rl.id !== regularLineId)
      this.sync()
    })
  }

  /**
   * POST /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId/destination-address
   */
  async postDestination(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    return postDestinationRequest(id, variantId, formData)
      .then(({ data }) => {
        this.sync()
        return data
      })
      .catch(this.catchError)
  }

  /**
   * PATCH /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId/destination-address/:destinationId
   */
  async patchDestination(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    return patchDestinationRequest(id, variantId, formData)
      .then(() => { this.sync() })
      .catch(this.catchError)
  }

  /**
   * DELETE /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId/destination-address/:destinationId
   */
  async deleteDestination(rlId, destinationId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductDestinationRequest(id, variantId, rlId, destinationId).then(() => {
      const rlIndex = this.mainVariant.regularLines.findIndex(rl => rl.id === rlId)

      this.mainVariant.regularLines[rlIndex].destinations = this.mainVariant.regularLines[rlIndex].destinations.filter(destination => destination.id !== destinationId)
      this.sync()
    })
  }

  /**
   * POST /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId/destination-address/:destinationId/deposit-zone
   */
  async postDestinationDZ(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    return postDestinationDZRequest(id, variantId, formData)
      .then(({ data }) => {
        this.sync()
        return data
      })
      .catch(this.catchError)
  }

  /**
   * PATCH /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId/destination-address/:destinationId/deposit-zone/:depositZoneId
   */
  async patchDestinationDZ(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    return patchDestinationDZRequest(id, variantId, formData)
      .then(() => {
        this.sync()
      })
      .catch(this.catchError)
  }

  /**
   * DELETE /helicopter-product/:id/helicopter-variant/:variantId/regular-line/:regularLineId/destination-address/:destinationId/deposit-zone/:depositZoneId
   */
  async deleteDestinationDZ(rlId, destinationId, dzId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductDestinationDZRequest(id, variantId, rlId, destinationId, dzId).then(() => {
      const rlIndex = this.mainVariant.regularLines.findIndex(rl => rl.id === rlId)
      const destinationsIndex = this.mainVariant.regularLines[rlIndex].destinations.findIndex(destination => destination.id === destinationId)

      this.mainVariant.regularLines[rlIndex].destinations[destinationsIndex].depositZones = this.mainVariant.regularLines[rlIndex].destinations[destinationsIndex].depositZones.filter(dz => dz.id !== dzId)
      this.sync()
    })
  }

  /**
   * POST /helicopter-product/{id}/availability/{variantId}/calendar
   */
  async postHelicopterAvailability(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return postHelicopterAvailabilityRequest(id, variantId, formData)
      .then(({ data }) => {
        this.sync()
        return data
      })
      .catch(this.catchError)
  }

  /**
   * GET /helicopter-product/{id}/availability/{variantId}/calendar
   */
  async fetchHelicopterAvailabilities(datesRange, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchHelicopterAvailabilitiesRequest(id, variantId, datesRange).then(({ data }) => {
      this.set({
        id,
        mainVariant: { ...this.mainVariant, id: variantId },
        availabilitiesHours: data.availabilitiesHours,
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * GET /helicopter-product/{id}/availability/{variantId}/calendar/{availabilityId}
   */
  async fetchHelicopterAvailability(availabilityId, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(availabilityId, id, variantId)

    return fetchHelicopterAvailabilityRequest(id, variantId, availabilityId).then(({ data }) => {
      // this.set({
      //   id,
      //   mainVariant: { ...this.mainVariant, id: variantId },
      //   availabilitiesHours: data.availabilitiesHours,
      // })
      this.sync()
      return data
    }).catch(this.catchError)
  }

  /**
   * PATCH /helicopter-product/{id}/availability/{variantId}/calendar/{availabilityId}
   */
  async patchHelicopterAvailability(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return patchHelicopterAvailabilityRequest(id, variantId, formData)
      .then(({ data }) => {
        this.sync()
        return data
      })
      .catch(this.catchError)
  }

  /**
   * DELETE /helicopter-product/{id}/availability/{variantId}/calendar/{availabilityId}
   */
  async deleteHelicopterAvailability(availabilityId, id = this.id, variantId = this.mainVariant.id) {
    deleteHelicopterAvailabilityRequest(id, variantId, availabilityId).then(() => {
      this.set({
        availabilitiesHours: this.availabilitiesHours.filter(availabilityHour => availabilityHour.id !== availabilityId),
      })
      this.sync()
    })
  }

  /**
   * GET /helicopter-product/:id/helicopter-variant/:variantId/base
   */
  async fetchBases(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductBasesRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
        bases: data.items,
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * POST /helicopter-product/:id/helicopter-variant/:variantId/base
   */
  async postBase(formData, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...pick(formData.cityBase, 'address', 'countryCode', 'postalCode', 'city'),
      active: formData.active,
    }
    return postProductBaseRequest(id, variantId, payload).catch(this.catchError)
  }

  /**
   * patch /helicopter-product/:id/helicopter-variant/:variantId/base/:baseId
   */
  async patchBase(formData, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...pick(formData.cityBase, 'address', 'countryCode', 'postalCode', 'city'),
      ...pick(formData, 'id', 'active'),
    }
    return patchProductBaseRequest(id, variantId, payload).catch(this.catchError)
  }

  /**
   * DELETE /helicopter-product/:id/helicopter-variant/:variantId/base/:baseId
   */
  async deleteBase(baseId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductBaseRequest(id, variantId, baseId)
  }

  /**
   * POST /helicopter-product/:id/helicopter-variant/:variantId/base/:baseId/deposit-zone
   */
  async postDepositZone(formData, id = this.id, variantId = this.mainVariant.id) {
    return postProductDepositZoneRequest(id, variantId, formData).catch(this.catchError)
  }

  /**
   * PATCH /helicopter-product/:id/helicopter-variant/:variantId/base/:baseId/deposit-zone/:dzId
   */
  async patchDepositZone(formData, baseId, dzId, id = this.id, variantId = this.mainVariant.id) {
    await patchProductDepositZoneRequest(id, variantId, baseId, dzId, formData)
  }

  /**
   * DELETE /helicopter-product/:id/helicopter-variant/:variantId/base/:baseId/deposit-zone/:dzId
   */
  async deleteDepositZone(baseId, dzId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductDepositZoneRequest(id, variantId, baseId, dzId)
  }

  /**
   * GET /helicopter-product/:id/variant/:variantId/negotiated-rate
   * @returns {Object} pagination
   */
  async fetchNegotiatedRates(params = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductNegotiatedRatesRequest(id, variantId, params).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
        negotiatedRates: data.items,
      })
      this.sync()
      return omit(data, 'product', 'variant', 'items')
    }).catch(this.catchError)
  }

  /**
   * GET /helicopter-product/:id/negotiated-rate/:variantId/:negociatedRateId
   */
  async fetchNegotiatedRateById(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductNegotiatedRateByIdRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * POST /helicopter-product/:id/variant/:variant_id/negotiated-rate
   */
  async postNegotiatedRate(payload, productId = this.id, variantId = this.mainVariant.id) {
    postProductNegotiatedRateRequest({ productId, variantId }, payload)
  }

  /**
   * PATCH /helicopter-product/:id/variant/:variantId/negotiated-rate/:negotiatedRate
   */
  async patchNegotiatedRate(negotiatedRateId, payload, productId = this.id, variantId = this.mainVariant.id) {
    return patchProductNegotiatedRateRequest({ productId, variantId, negotiatedRateId }, payload)
  }

  /**
   * DELETE /helicopter-product/:id/variant/:variantId/negotiated-rate/:negociatedRateId
   */
  async deleteNegotiatedRateById(negotiatedRateId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductNegotiatedRateByIdRequest(id, variantId, negotiatedRateId)
  }

  /**
   * TODO
   */
  async fetchTripStepEventRegularFlight(tripStepId, organizationHelicopterId) {
    return fetchTripStepEventRegularFlightHelicopterRequest(tripStepId, organizationHelicopterId)
  }

  /**
   * GET /helicopter-product/:id/price-management/:variantId
   * ? Never used
   */
  // async fetchCancellationConditions(id = this.id, variantId = this.mainVariant.id) {
  //   await this.checkArgs(id, variantId)

  //   return fetchProductCancellationConditionsRequest(id, variantId).then(({ data }) => {
  //     this.set({
  //       ...data.product,
  //       conditions: data.items,
  //       mainVariant: { ...this.mainVariant, ...data.variant },
  //     })
  //     this.sync()
  //   }).catch(this.catchError)
  // }

  /**
   * Cancellation conditions of product -> PATCH /helicopter-product/:id/cancellation-conditions/:variant_id
   * ? Never used
   */
  // async patchCancellationConditions(productId = this.id, variantId = this.mainVariant.id) {
  //   const payload = {
  //     customConditionEnabled: this.mainVariant.customCancellationPolicyConditionEnabled,
  //     customConditions: this.conditions,
  //     deletedCustomConditionIds: differenceWith(
  //       this._reference.conditions,
  //       this.conditions,
  //       isEqual,
  //     ).map(condition => condition.id),
  //     cancellationPolicyId: this.mainVariant.cancellationPolicy
  //       ? this.mainVariant.cancellationPolicy.id
  //       : null,
  //   }
  //   await patchProductCancellationConditionsRequest({ productId, variantId }, payload)
  //   this.sync()
  // }
}

export default ProductHelicopter
