/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */

import { differenceWith, isEqual, omit, pick, pickBy } from 'lodash'

import {
  fetchProductRequest,
  fetchProductPlainRequest,
  fetchProductLocationsRequest,
  fetchProductPriceRequest,
  patchProductPriceRequest,
  fetchProductNegotiatedRatesRequest,
  // fetchProductCancellationConditionsRequest,
  // patchProductCancellationConditionsRequest,
  patchProductRequest,
  patchProductPlainRequest,
  patchProductLocationsRequest,
  fetchProductNegotiatedRateByIdRequest,
  deleteProductNegotiatedRateByIdRequest,
  postProductNegotiatedRateRequest,
  patchProductNegotiatedRateRequest,
  postProductPriceCalendarRequest,
  fetchProductPriceCalendarRequest,
  fetchProductPriceCalendarByIdRequest,
  patchProductPriceCalendarRequest,
  deleteProductPriceCalendarRequest,
} from '@/request/globalApi/requests/productRequests'
import store from '@/store/store'
import BaseModel from './_Base'

/**
 * Product model
 * @link https://vuemc.io/#basic-usage
 * @link https://vuemc.io/#model-data-access-active ( .sync(), .reset() etc )
 * @example use in /src/views/product/ProductWizard.vue
 */
class ProductService extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      name: {
        // TODO: dynamic lang init
        fr: '',
        en: '',
      },
      airports: [],
      baseReference: '',
      typology: 'service',
      state: '',
      timezone: '',
      vatRate: 0,
      active: false,
      visible: true,
      defaultLang: {
        code: store.state.auth.defaultLang,
      },
      mainCategory: {
        id: null,
        name: {
          // TODO: dynamic lang init
          fr: '',
          en: '',
        },
      },
      mainVariant: {
        id: null,
        attrs: [],
        // TODO: dynamic lang init
        description: { en: '', fr: '' },
        files: [],
        visibilityEndAt: '',
        visibilityStartAt: '',
        packing: {},
        visible: true,
        prices: [],
        priceRanges: [],
        priceRangesFull: [],
        pricingType: '',
        negotiatedRates: [],
        vatRate: 0,
      },
      conditions: [],
      negotiatedRates: [],
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      name: Object,
      airports: airports => airports || [],
      baseReference: String,
      typology: String,
      state: String,
      timezone: String,
      active: Boolean,
      defaultLang: Object,
      mainCategory: Object,
      mainVariant: Object,
      conditions: conditions => conditions || [],
      negotiatedRates: negotiatedRates => negotiatedRates || [],
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
   * GET /product/:id
   */
  async fetch(id = this.id) {
    await this.checkArgs(id)

    await fetchProductRequest(id).then(({ data }) => {
      const prod = {
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.product.mainVariant },
      }
      this.set(prod)
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit a product -> PATCH /product/:id
   */
  async patch(productId = this.id) {
    const payload = {
      ...pick(this, 'name', 'baseReference', 'timezone', 'visible'),
      mainCategoryId: this.mainCategory.id,
      defaultLangCode: this.defaultLang.code,
    }
    await patchProductRequest(productId, payload).then(() => {
      // If product has not a mainVariant, patch() will complete it, then will create the mainVariant
      // Will enable all tabs of ProductWizard
      if (!this.mainVariant.id) this.fetch(productId)
    })
  }

  /**
   * GET /product/:id/additional-informations/:variantId
   */
  async fetchPlain(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductPlainRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: {
          ...this.mainVariant,
          ...omit(data.variant, 'variantAttributes'),
          // ? Trick api, it return an array if no description, or object if has got description
          description: data.variant.description.length !== undefined
            ? {}
            : data.variant.description,
          attrs: data.variant.variantAttributes.map(attr => {
            const normalizeAttr = {
              ...attr,
              type: attr.hasOwnProperty('selectValue')
                ? 'select'
                : attr.hasOwnProperty('choiceValues')
                  ? 'choice'
                // Delete 'inputValue' after update api
                  : (attr.hasOwnProperty('inputValue') || attr.hasOwnProperty('inputValues'))
                    ? 'input'
                    : undefined,
            }
            // ? NOTE: delete this condition after modification api (s)
            if (normalizeAttr.hasOwnProperty('inputValue')) {
              normalizeAttr.inputValues = normalizeAttr.inputValue
              delete normalizeAttr.inputValue
            }
            return normalizeAttr
          }),
        },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit the mainVariant of product -> PATCH /product/:id/main-information/:variant_id
   */
  async patchPlain(productId = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...pick(this.mainVariant, 'visibilityStartAt', 'visibilityEndAt'),
      description: pickBy(this.mainVariant.description, lang => !!lang),
      variantAttributes: this.mainVariant.attrs.map(attr => {
        const values = attr.type === 'select'
          ? { selectValueId: attr.selectValue.id }
          : attr.type === 'choice'
            ? { choiceValueIds: attr.choiceValues.map(choice => choice.id) }
            : { inputValues: attr.inputValues }
        return {
          id: attr.id,
          attributeId: attr.attribute.id,
          type: attr.type,
          ...values,
        }
      }),
    }
    const listToDelete = differenceWith(
      this._reference.mainVariant.files,
      this.mainVariant.files,
      isEqual,
    )
    const listToCreate = differenceWith(
      this.mainVariant.files,
      this._reference.mainVariant.files,
      isEqual,
    )

    const posAlreadyExist = this.mainVariant.files.map(file => file.position)
      // Impossible to replace a position in same request
      .concat(listToDelete.map(file => file.position))
    let position = 0

    payload.deletedFileIds = listToDelete.map(file => file.id)
    payload.files = listToCreate.map(file => {
      // Prevent all positions who already exists
      position += 1
      while (posAlreadyExist.includes(position)) {
        position += 1
      }
      return { ...file, position }
    })

    await patchProductPlainRequest({ productId, variantId }, payload)
    this.sync()
  }

  /**
   * GET /product/:id/price-management/:variantId
   */
  async fetchPrice(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductPriceRequest(id, variantId).then(({ data }) => {
      let variant
      if (!data.variant.calendarMode) {
        variant = data.variant
        variant.prices = variant.prices.map(price => {
          const normalizeDuration = {
            fromQuantiUnit: 'min',
            toQuantityUnit: 'min',
          }
          if (variant.packing.type === 'minutes') {
            const setDuration = quantity => {
              normalizeDuration[`${quantity}Unit`] = 'hour'
              if (price[quantity] >= 1440) {
                normalizeDuration[`${quantity}Unit`] = 'day'
              } else if (price[quantity] !== null && price[quantity] < 60) {
                normalizeDuration[`${quantity}Unit`] = 'min'
              }
            }
            setDuration('fromQuantity')
            setDuration('toQuantity')
          }
          return { ...price, ...normalizeDuration }
        })
      } else {
        variant = omit(data.variant, 'prices')
      }
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...variant },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * PATCH /product/:id/price-management/:variantId
   */
  async patchPrice(formData = null, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...pick(this.mainVariant, 'pricingType', 'visible', 'vatRate', 'prices'),
      packingId: this.mainVariant.packing ? this.mainVariant.packing.id : null,
    }
    await patchProductPriceRequest(id, variantId, formData || payload)
    this.sync()
  }

  /**
   * GET /product/:id/price-management/:variantId/calendar
   */
  async fetchPriceCalendar(queryParams = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductPriceCalendarRequest(queryParams, id, variantId).then(({ data }) => {
      this.set({
        mainVariant: { ...this.mainVariant, ...data, prices: [] },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * GET /product/:id/price-management/:variantId/calendar/:rangeId
   */
  async fetchPriceCalendarById(rangeId, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(rangeId, id, variantId)

    return fetchProductPriceCalendarByIdRequest(id, variantId, rangeId).then(({ data }) => {
      const hourPrices = data.hourPrices.map(hourPrice => {
        const prices = hourPrice.prices.map(price => {
          const normalizeDuration = {
            fromQuantityUnit: 'hour',
            toQuantityUnit: 'hour',
          }
          if (this.mainVariant.packing.type === 'hours') {
            const setDuration = quantity => {
              if (price[quantity] >= 1440) {
                normalizeDuration[`${quantity}Unit`] = 'day'
              } else if (price[quantity] !== null && price[quantity] < 60) {
                normalizeDuration[`${quantity}Unit`] = 'min'
              }
            }
            setDuration('fromQuantity')
            setDuration('toQuantity')
          }
          return { ...price, ...normalizeDuration }
        })
        return {
          ...hourPrice,
          prices,
        }
      })
      this.set({
        mainVariant: {
          ...this.mainVariant, priceRangesFull: [{ ...data, hourPrices }], prices: [],
        },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * POST /product/:id/price-management/:variantId/calendar
   */
  async postPriceCalendar(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...formData,
      endAt: formData.endAt ? formData.endAt : null,
      daysOfWeek: formData.daysOfWeek.length
        ? formData.daysOfWeek
        : [0, 1, 2, 3, 4, 5, 6],
    }
    await postProductPriceCalendarRequest(id, variantId, payload)
    // TODO this.set(formdata)
  }

  /**
   * PATCH /product/:id/price-management/:variantId/calendar/:rangeId
   */
  async patchPriceCalendar(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...omit(formData, 'id'),
      endAt: formData.endAt || null,
      daysOfWeek: formData.daysOfWeek.length
        ? formData.daysOfWeek
        : [0, 1, 2, 3, 4, 5, 6],
    }
    await patchProductPriceCalendarRequest(id, variantId, formData.id, payload)
  }

  /**
   * DELETE /product/:id/price-management/:variantId/calendar/:rangeId
   */
  async deletePriceCalendar(rangeId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductPriceCalendarRequest(id, variantId, rangeId)
  }

  /**
   * GET /product/:id/variant/:variantId/negotiated-rate
   * @returns {Object} pagination
   */
  async fetchNegotiatedRates(params = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductNegotiatedRatesRequest(id, variantId, params).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
        negotiatedRates: data.items,
      })
      this.sync()
      return omit(data, 'product', 'variant', 'items')
    }).catch(this.catchError)
  }

  /**
   * GET /product/:id/negotiated-rate/:variantId/:negociatedRateId
   */
  async fetchNegotiatedRateById(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchProductNegotiatedRateByIdRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * POST /product/:id/variant/:variant_id/negotiated-rate
   */
  async postNegotiatedRate(payload, productId = this.id, variantId = this.mainVariant.id) {
    postProductNegotiatedRateRequest({ productId, variantId }, payload)
  }

  /**
   * PATCH /product/:id/variant/:variantId/negotiated-rate/:negotiatedRateId
   */
  async patchNegotiatedRate(negotiatedRateId, payload, productId = this.id, variantId = this.mainVariant.id) {
    return patchProductNegotiatedRateRequest({ productId, variantId, negotiatedRateId }, payload)
  }

  /**
   * DELETE /product/:id/variant/:variantId/negotiated-rate/:negociatedRateId
   */
  async deleteNegotiatedRateById(negotiatedRateId, id = this.id, variantId = this.mainVariant.id) {
    await deleteProductNegotiatedRateByIdRequest(id, variantId, negotiatedRateId)
  }

  /**
   * GET /product/:id/variant/!variantId/cancellation-conditions
   * ? Never used
   */
  // async fetchCancellationConditions(id = this.id, variantId = this.mainVariant.id) {
  //   await this.checkArgs(id, variantId)

  //   return fetchProductCancellationConditionsRequest(id, variantId).then(({ data }) => {
  //     this.set({
  //       ...data.product,
  //       conditions: data.items,
  //       mainVariant: { ...this.mainVariant, ...data.variant },
  //     })
  //     this.sync()
  //   }).catch(this.catchError)
  // }

  /**
   * Cancellation conditions of product -> PATCH /product/:id/cancellation-conditions/:variant_id
   * ? Never used
   */
  // async patchCancellationConditions(productId = this.id, variantId = this.mainVariant.id) {
  //   const payload = {
  //     customConditionEnabled: this.mainVariant.customCancellationPolicyConditionEnabled,
  //     customConditions: this.conditions,
  //     deletedCustomConditionIds: differenceWith(
  //       this._reference.conditions,
  //       this.conditions,
  //       isEqual,
  //     ).map(condition => condition.id),
  //     cancellationPolicyId: this.mainVariant.cancellationPolicy
  //       ? this.mainVariant.cancellationPolicy.id
  //       : null,
  //   }
  //   await patchProductCancellationConditionsRequest({ productId, variantId }, payload)
  //   this.sync()
  // }

  /**
   * GET /product/:id/locations
   */
  async fetchLocations(id = this.id) {
    await this.checkArgs(id)

    return fetchProductLocationsRequest(id).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
        airports: data.airportsProducts,
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit the locations of product -> PATCH /product/:id/locations
   */
  async patchLocations(productId = this.id) {
    const payload = {
      airportsProducts: this.airports.map(({ active, airport }) => (
        { active, airportId: airport.id }
      )),
    }
    await patchProductLocationsRequest(productId, payload)
  }
}

export default ProductService
