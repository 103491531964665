import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
const END_POINT = 'api/package-product'

// --------------------
//    API Requests
// --------------------

// ADDITIONAL INFORMATIONS

export const fetchPackageProductPlainRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/additional-informations`)

export const patchPackageProductPlainRequest = ({ productId, variantId }, product, message) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/additional-informations`, product, { message })

// DISCOUNT
export const fetchPackageProductDiscountRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/discount`)

export const patchPackageProductDiscountRequest = (productId, variantId, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/discount`, product)

// PRICE MANAGEMENT

export const fetchPackageProductPriceRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/price-management`)

export const patchPackageProductPriceRequest = (productId, variantId, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/price-management`, product)

// CALENDAR

export const fetchPackageProductPriceCalendarRequest = (queryParams, productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar`, { params: queryParams })

export const fetchPackageProductPriceCalendarByIdRequest = (productId, variantId, rangeId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar/${rangeId}`)

export const postPackageProductPriceCalendarRequest = (productId, variantId, product) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar`, product)

export const patchPackageProductPriceCalendarRequest = (productId, variantId, rangeId, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar/${rangeId}`, product)

export const deletePackageProductPriceCalendarRequest = (productId, variantId, rangeId) => globalApi.delete(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar/${rangeId}`)

// NEGOTIATED RATES

export const fetchPackageProductNegotiatedRatesRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`, { params: queryParams })

export const postPackageProductNegotiatedRateRequest = ({ productId, variantId }, product) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`, product)

export const patchPackageProductNegotiatedRateRequest = ({ productId, variantId, negotiatedRateId }, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate/${negotiatedRateId}`, product)

export const fetchPackageProductNegotiatedRateByIdRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`)

export const deletePackageProductNegotiatedRateByIdRequest = (productId, variantId, negotiatedRateId) => fireDeleteAlert().then(isConfirmed => isConfirmed
  && globalApi.delete(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate/${negotiatedRateId}`))

// CANCELLATION CONDITIONS

// ? Never used
// export const fetchPackageProductVariantCancellationConditionsRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/cancellation-conditions`)

// ? Never used
// export const patchPackageProductVariantCancellationConditionsRequest = (productId, variantId, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/cancellation-conditions`, product)

// SERVICE

export const fetchPackageProductServicesRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/service`)

export const postProductPackageServiceRequest = (productId, variantId, payload) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/service`, payload)

export const deletePackageProductServiceRequest = (productId, variantId, serviceId) => globalApi.delete(`${END_POINT}/${productId}/variant/${variantId}/service/${serviceId}`)
