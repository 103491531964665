import { omit } from 'lodash'
import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/helicopter-product'

// --------------------
//    API Requests
// --------------------

// ADDITIONAL INFORMATIONS

export const fetchProductPlainRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/helicopter-variant/${variantId}/additional-informations`)

export const patchProductPlainRequest = ({
  productId,
  variantId,
}, product, message) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/additional-informations`, product, { message })

// HELICOPTER MANAGEMENT

export const fetchProductHelicoptersRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/helicopter-variant/${variantId}/helicopter-management`, { params: queryParams })

export const deleteProductHelicopterByIdRequest = (productId, variantId, helicopterId) => globalApi.delete(`${END_POINT}/${productId}/helicopter-variant/${variantId}/helicopter-management/${helicopterId}`)

export const postProductHelicopterRequest = (productId, variantId, payload) => globalApi.post(`${END_POINT}/${productId}/helicopter-variant/${variantId}/helicopter-management`, payload)

export const patchProductHelicopterRequest = (productId, variantId, helicopter) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/helicopter-management/${helicopter.id}`, omit(helicopter, 'id'))

// GENERAL FARE MANAGEMENT

export const fetchProductFareManagementRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/helicopter-variant/${variantId}/general-fare-management`)

export const patchFareManagementRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/general-fare-management`, payload)

export const fetchRegularLineRequest = (productId, variantId, regularLineId, queryParams) => globalApi.get(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${regularLineId}`, { params: queryParams })

export const postRegularLineRequest = (productId, variantId, payload) => globalApi.post(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line`, payload)

export const patchRegularLineRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${payload.id}`, omit(payload, 'id'))

export const deleteProductRegularLineRequest = (productId, variantId, regularLineId) => globalApi.delete(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${regularLineId}`)

export const postDestinationRequest = (productId, variantId, payload) => globalApi.post(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${payload.rlId}/destination-address`, omit(payload, 'id', 'rlId'))

export const patchDestinationRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${payload.rlId}/destination-address/${payload.id}`, omit(payload, 'id', 'rlId'))

export const deleteProductDestinationRequest = (productId, variantId, rlId, destinationId) => globalApi.delete(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${rlId}/destination-address/${destinationId}`)

export const postDestinationDZRequest = (productId, variantId, payload) => globalApi.post(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${payload.rlId}/destination-address/${payload.destinationId}/deposit-zone`, omit(payload, 'id', 'rlId', 'destinationId'))

export const patchDestinationDZRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${payload.rlId}/destination-address/${payload.destinationId}/deposit-zone/${payload.id}`, omit(payload, 'id', 'rlId', 'destinationId'))

export const deleteProductDestinationDZRequest = (productId, variantId, rlId, destinationId, dzId) => globalApi.delete(`${END_POINT}/${productId}/helicopter-variant/${variantId}/regular-line/${rlId}/destination-address/${destinationId}/deposit-zone/${dzId}`)

// Availabilities

export const postHelicopterAvailabilityRequest = (productId, variantId, payload) => globalApi.post(`${END_POINT}/${productId}/availability/${variantId}/calendar`, payload)

export const fetchHelicopterAvailabilitiesRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/availability/${variantId}/calendar`, { params: queryParams })

export const fetchHelicopterAvailabilityRequest = (productId, variantId, availabilityId) => globalApi.get(`${END_POINT}/${productId}/availability/${variantId}/calendar/${availabilityId}`)

export const patchHelicopterAvailabilityRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/availability/${variantId}/calendar/${payload.id}`, omit(payload, 'id'))

export const deleteHelicopterAvailabilityRequest = (productId, variantId, availabilityId) => globalApi.delete(`${END_POINT}/${productId}/availability/${variantId}/calendar/${availabilityId}`)

// HELICOPTER BASES

export const fetchProductBasesRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/helicopter-variant/${variantId}/base`)

export const postProductBaseRequest = (productId, variantId, payload) => globalApi.post(`${END_POINT}/${productId}/helicopter-variant/${variantId}/base`, payload)

export const patchProductBaseRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/base/${payload.id}`, omit(payload, 'id'))

export const deleteProductBaseRequest = (productId, variantId, baseId) => globalApi.delete(`${END_POINT}/${productId}/helicopter-variant/${variantId}/base/${baseId}`)

// DEPOSIT ZONES

export const postProductDepositZoneRequest = (productId, variantId, payload) => globalApi.post(`${END_POINT}/${productId}/helicopter-variant/${variantId}/base/${payload.baseId}/deposit-zone`, omit(payload, 'baseId'))

export const patchProductDepositZoneRequest = (productId, variantId, baseId, dzId, payload) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/base/${baseId}/deposit-zone/${dzId}`, omit(payload, 'baseId'))

export const deleteProductDepositZoneRequest = (productId, variantId, baseId, dzId) => globalApi.delete(`${END_POINT}/${productId}/helicopter-variant/${variantId}/base/${baseId}/deposit-zone/${dzId}`)

// NEGOTIATED RATES

export const fetchProductNegotiatedRatesRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/helicopter-variant/${variantId}/negotiated-rate`, { params: queryParams })

export const postProductNegotiatedRateRequest = ({
  productId,
  variantId,
}, product) => globalApi.post(`${END_POINT}/${productId}/helicopter-variant/${variantId}/negotiated-rate`, product)

export const patchProductNegotiatedRateRequest = ({
  productId,
  variantId,
  negotiatedRateId,
}, product) => globalApi.patch(`${END_POINT}/${productId}/helicopter-variant/${variantId}/negotiated-rate/${negotiatedRateId}`, product)

export const fetchProductNegotiatedRateByIdRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/helicopter-variant/${variantId}/negotiated-rate`)

export const deleteProductNegotiatedRateByIdRequest = (productId, variantId, negotiatedRateId) => fireDeleteAlert().then(isConfirmed => isConfirmed
  && globalApi.delete(`${END_POINT}/${productId}/helicopter-variant/${variantId}/negotiated-rate/${negotiatedRateId}`))
