/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */

import { pick } from 'lodash'

import { postProductRequest } from '@/request/globalApi/requests/productRequests'
import BaseModel from './_Base'

/**
 * Product model
 * @link https://vuemc.io/#basic-usage
 * @link https://vuemc.io/#model-data-access-active ( .sync(), .reset() etc )
 * @exanple use in /src/views/product/ProductWizard.vue
 */
class Product extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      name: {
        // TODO: dynamic lang init
        fr: '',
        en: '',
      },
      baseReference: '',
      typology: 'service',
      active: false,
      visible: true,
      mainCategory: {
        id: null,
        // TODO: Dynamic lang init
        name: {
          fr: '',
          en: '',
        },
      },
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      name: Object,
      baseReference: String,
      typology: String,
      active: Boolean,
      mainCategory: Object,
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
   * Create a product -> POST /product/:id
   */
  async post() {
    const payload = {
      ...pick(this, 'name', 'typology', 'visible', 'baseReference'),
      mainCategoryId: this.mainCategory.id,
    }
    const { data } = await postProductRequest(payload)
    this.set({
      ...data.product,
      mainVariant: { ...this.mainVariant, ...data.variant },
    })
  }
}

export default Product
