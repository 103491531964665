/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */

import { differenceWith, isEqual, omit, pick, pickBy } from 'lodash'

import {
  fetchProductRequest,
  patchProductRequest,
  fetchProductLocationsRequest,
  patchProductLocationsRequest,
} from '@/request/globalApi/requests/productRequests'

import {
  fetchPackageProductPlainRequest,
  fetchPackageProductPriceRequest,
  patchPackageProductPriceRequest,
  fetchPackageProductNegotiatedRatesRequest,
  // fetchPackageProductVariantCancellationConditionsRequest,
  // patchPackageProductVariantCancellationConditionsRequest,
  patchPackageProductPlainRequest,
  fetchPackageProductNegotiatedRateByIdRequest,
  deletePackageProductNegotiatedRateByIdRequest,
  postPackageProductNegotiatedRateRequest,
  patchPackageProductNegotiatedRateRequest,
  postPackageProductPriceCalendarRequest,
  fetchPackageProductPriceCalendarRequest,
  fetchPackageProductPriceCalendarByIdRequest,
  patchPackageProductPriceCalendarRequest,
  deletePackageProductPriceCalendarRequest,
  fetchPackageProductServicesRequest,
  postProductPackageServiceRequest,
  deletePackageProductServiceRequest,
  fetchPackageProductDiscountRequest,
  patchPackageProductDiscountRequest,
} from '@/request/globalApi/requests/productPackageRequests'
import store from '@/store/store'
import BaseModel from './_Base'

/**
 * Product package model
 * @link https://vuemc.io/#basic-usage
 * @link https://vuemc.io/#model-data-access-active ( .sync(), .reset() etc )
 * @example use in /src/views/product/ProductWizard.vue
 */
class ProductPackage extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      name: {
        // TODO: dynamic lang init
        fr: '',
        en: '',
      },
      airports: [],
      baseReference: '',
      typology: 'package',
      state: '',
      timezone: '',
      vatRate: 0,
      active: false,
      visible: true,
      defaultLang: {
        code: store.state.auth.defaultLang,
      },
      mainCategory: {
        id: null,
        name: {
          // TODO: dynamic lang init
          fr: '',
          en: '',
        },
      },
      mainVariant: {
        id: null,
        attrs: [],
        // TODO: dynamic lang init
        description: { en: '', fr: '' },
        files: [],
        visibilityEndAt: '',
        visibilityStartAt: '',
        packing: {},
        visible: true,
        prices: [],
        priceRanges: [],
        priceRangesFull: [],
        pricingType: '',
        negotiatedRates: [],
        vatRate: 0,
        discount: 0.0,
        discountMode: 'percent',
      },
      conditions: [],
      negotiatedRates: [],
      services: [],
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      name: Object,
      airports: airports => airports || [],
      baseReference: String,
      typology: String,
      state: String,
      timezone: String,
      active: Boolean,
      defaultLang: Object,
      mainCategory: Object,
      mainVariant: Object,
      conditions: conditions => conditions || [],
      negotiatedRates: negotiatedRates => negotiatedRates || [],
      services: services => services || [],
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
   * GET /product/:id
   */
  async fetch(id = this.id) {
    await this.checkArgs(id)

    await fetchProductRequest(id).then(({ data }) => {
      const prod = {
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.product.mainVariant },
      }
      this.set(prod)
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit a product -> PATCH /product/:id
   */
  async patch(productId = this.id) {
    const payload = {
      ...pick(this, 'name', 'baseReference', 'timezone', 'visible'),
      mainCategoryId: this.mainCategory.id,
      defaultLangCode: this.defaultLang.code,
    }
    await patchProductRequest(productId, payload)
  }

  /**
   * GET /package-product/:id/additional-informations/:variantId
   */
  async fetchPlain(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchPackageProductPlainRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: {
          ...this.mainVariant,
          ...omit(data.variant, 'variantAttributes'),
          // ? Trick api, it return an array if no description, or object if has got description
          description: data.variant.description.length !== undefined
            ? {}
            : data.variant.description,
          attrs: data.variant.variantAttributes.map(attr => {
            const normalizeAttr = {
              ...attr,
              type: attr.hasOwnProperty('selectValue')
                ? 'select'
                : attr.hasOwnProperty('choiceValues')
                  ? 'choice'
                // Delete 'inputValue' after update api
                  : (attr.hasOwnProperty('inputValue') || attr.hasOwnProperty('inputValues'))
                    ? 'input'
                    : undefined,
            }
            // ? NOTE: delete this condition after modification api (s)
            if (normalizeAttr.hasOwnProperty('inputValue')) {
              normalizeAttr.inputValues = normalizeAttr.inputValue
              delete normalizeAttr.inputValue
            }
            return normalizeAttr
          }),
        },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit the mainVariant of product -> PATCH /package-product/:id/main-information/:variant_id
   */
  async patchPlain(productId = this.id, variantId = this.mainVariant.id) {
    const payload = {
      description: pickBy(this.mainVariant.description, lang => !!lang),
      variantAttributes: this.mainVariant.attrs.map(attr => {
        const values = attr.type === 'select'
          ? { selectValueId: attr.selectValue.id }
          : attr.type === 'choice'
            ? { choiceValueIds: attr.choiceValues.map(choice => choice.id) }
            : { inputValues: attr.inputValues }
        return {
          id: attr.id,
          attributeId: attr.attribute.id,
          type: attr.type,
          ...values,
        }
      }),
      visibilityStartAt: this.mainVariant.visibilityStartAt,
      visibilityEndAt: this.mainVariant.visibilityEndAt,
    }
    const listToDelete = differenceWith(
      this._reference.mainVariant.files,
      this.mainVariant.files,
      isEqual,
    )
    const listToCreate = differenceWith(
      this.mainVariant.files,
      this._reference.mainVariant.files,
      isEqual,
    )

    const posAlreadyExist = this.mainVariant.files.map(file => file.position)
      // Impossible to replace a position in same request
      .concat(listToDelete.map(file => file.position))
    let position = 0

    payload.deletedFileIds = listToDelete.map(file => file.id)
    payload.files = listToCreate.map(file => {
      // Prevent all positions who already exists
      position += 1
      while (posAlreadyExist.includes(position)) {
        position += 1
      }
      return { ...file, position }
    })

    await patchPackageProductPlainRequest({ productId, variantId }, payload)
    this.sync()
  }

  /**
   * GET /package-product/:id/variant/:variantId/price-management
   */
  async fetchPrice(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchPackageProductPriceRequest(id, variantId).then(({ data }) => {
      const variant = !data.variant.calendarMode ? data.variant : omit(data.variant, 'prices')
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...variant },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * PATCH /package-product/:id/variant/:variantId/price-management
   */
  async patchPrice(formData = null, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...pick(this.mainVariant, 'pricingType', 'visible', 'vatRate', 'prices'),
      packingId: this.mainVariant.packing ? this.mainVariant.packing.id : null,
    }
    await patchPackageProductPriceRequest(id, variantId, formData || payload)
    this.sync()
  }

  /**
   * GET /package-product/:id/price-management/:variantId/calendar
   */
  async fetchPriceCalendar(queryParams = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchPackageProductPriceCalendarRequest(queryParams, id, variantId).then(({ data }) => {
      this.set({
        mainVariant: { ...this.mainVariant, ...data, prices: [] },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * GET /package-product/:id/price-management/:variantId/calendar/:rangeId
   */
  async fetchPriceCalendarById(rangeId, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(rangeId, id, variantId)

    return fetchPackageProductPriceCalendarByIdRequest(id, variantId, rangeId).then(({ data }) => {
      this.set({
        mainVariant: {
          ...this.mainVariant, priceRangesFull: [data], prices: [],
        },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * POST /package-product/:id/price-management/:variantId/calendar
   */
  async postPriceCalendar(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...formData,
      endAt: formData.endAt ? formData.endAt : null,
      daysOfWeek: formData.daysOfWeek.length
        ? formData.daysOfWeek
        : [0, 1, 2, 3, 4, 5, 6],
    }
    await postPackageProductPriceCalendarRequest(id, variantId, payload)
    // TODO this.set(formdata)
  }

  /**
   * PATCH /package-product/:id/price-management/:variantId/calendar/:rangeId
   */
  async patchPriceCalendar(formData = {}, id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...omit(formData, 'id'),
      endAt: formData.endAt ? formData.endAt : null,
      daysOfWeek: formData.daysOfWeek.length
        ? formData.daysOfWeek
        : [0, 1, 2, 3, 4, 5, 6],
    }
    await patchPackageProductPriceCalendarRequest(id, variantId, formData.id, payload)
  }

  /**
   * DELETE /package-product/:id/price-management/:variantId/calendar/:rangeId
   */
  async deletePriceCalendar(rangeId, id = this.id, variantId = this.mainVariant.id) {
    await deletePackageProductPriceCalendarRequest(id, variantId, rangeId)
  }

  /**
   * GET /package-product/:id/variant/:variantId/negotiated-rate
   * @returns {Object} pagination
   */
  async fetchNegotiatedRates(params = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchPackageProductNegotiatedRatesRequest(id, variantId, params).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
        negotiatedRates: data.items,
      })
      this.sync()
      return omit(data, 'product', 'variant', 'items')
    }).catch(this.catchError)
  }

  /**
   * GET /package-product/:id/negotiated-rate/:variantId/:negociatedRateId
   */
  async fetchNegotiatedRateById(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchPackageProductNegotiatedRateByIdRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * POST /package-product/:id/variant/:variant_id/negotiated-rate
   */
  async postNegotiatedRate(payload, productId = this.id, variantId = this.mainVariant.id) {
    postPackageProductNegotiatedRateRequest({ productId, variantId }, payload)
  }

  /**
   * PATCH /package-product/:id/variant/:variantId/negotiated-rate/:negotiatedRate
   */
  async patchNegotiatedRate(negotiatedRateId, payload, productId = this.id, variantId = this.mainVariant.id) {
    return patchPackageProductNegotiatedRateRequest({ productId, variantId, negotiatedRateId }, payload)
  }

  /**
   * DELETE /package-product/:id/variant/:variantId/negotiated-rate/:negociatedRateId
   */
  async deleteNegotiatedRateById(negotiatedRateId, id = this.id, variantId = this.mainVariant.id) {
    await deletePackageProductNegotiatedRateByIdRequest(id, variantId, negotiatedRateId)
  }

  /**
   * GET /package-product/:id/price-management/:variantId/cancellation-conditions
   * ? Never used
   */
  // async fetchCancellationConditions(id = this.id, variantId = this.mainVariant.id) {
  //   await this.checkArgs(id, variantId)

  //   return fetchPackageProductCancellationConditionsRequest(id, variantId).then(({ data }) => {
  //     this.set({
  //       ...data.product,
  //       conditions: data.items,
  //       mainVariant: { ...this.mainVariant, ...data.variant },
  //     })
  //     this.sync()
  //   }).catch(this.catchError)
  // }

  /**
   * Cancellation conditions of product -> PATCH /package-product/:id/price-management/:variantId/cancellation-conditions
   * ? Never used
   */
  // async patchCancellationConditions(productId = this.id, variantId = this.mainVariant.id) {
  //   const payload = {
  //     customConditionEnabled: this.mainVariant.customCancellationPolicyConditionEnabled,
  //     customConditions: this.conditions,
  //     deletedCustomConditionIds: differenceWith(
  //       this._reference.conditions,
  //       this.conditions,
  //       isEqual,
  //     ).map(condition => condition.id),
  //     cancellationPolicyId: this.mainVariant.cancellationPolicy
  //       ? this.mainVariant.cancellationPolicy.id
  //       : null,
  //   }
  //   await patchPackageProductCancellationConditionsRequest({ productId, variantId }, payload)
  //   this.sync()
  // }

  /**
   * GET /package-product/:id/locations
   */
  async fetchLocations(id = this.id) {
    await this.checkArgs(id)

    return fetchProductLocationsRequest(id).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
        airports: data.airportsProducts,
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit the locations of product -> PATCH /package-product/:id/locations
   */
  async patchLocations(productId = this.id) {
    const payload = {
      airportsProducts: this.airports.map(({ active, airport }) => (
        { active, airportId: airport.id }
      )),
    }
    await patchProductLocationsRequest(productId, payload)
  }

  /**
   * GET /package-product/:id/variant/:variantId/service
   */
  async fetchPackageServices(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchPackageProductServicesRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, id: variantId },
        services: data.items,
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * GET /package-product/:id/variant/:variantId/discount
   */
  async fetchPackageDiscount(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchPackageProductDiscountRequest(id, variantId).then(({ data }) => {
      this.set({
        mainVariant: {
          ...this.mainVariant,
          ...data,
        },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * PATCH /package-product/:id/variant/:variantId/discount
   */
  async patchDiscount(id = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...pick(this.mainVariant, 'discount', 'discountMode'),
    }
    await patchPackageProductDiscountRequest(id, variantId, payload)
    this.sync()
  }

  /**
   * POST /helicopter-product/:id/helicopter-variant/:variantId/service
   */
  async postPackageService(formData, id = this.id, variantId = this.mainVariant.id) {
    await postProductPackageServiceRequest(id, variantId, formData)
    this.sync()
  }

  /**
   * DELETE /package-product/:id/variant/:variantId/service/:serviceId
   */
  async deletePackageService(serviceId, id = this.id, variantId = this.mainVariant.id) {
    await deletePackageProductServiceRequest(id, variantId, serviceId)
    this.sync()
  }
}

export default ProductPackage
