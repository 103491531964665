import { omit } from 'lodash'

import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
const END_POINT = 'api/hangar-product'

// --------------------
//    API Requests
// --------------------

// ADDITIONAL INFORMATIONS

export const fetchHangarProductPlainRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/additional-informations`)

export const patchHangarProductPlainRequest = ({ productId, variantId }, product, message) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/additional-informations`, product, { message })

// TECHNICAL INFORAMATIONS

export const fetchHangarProductTechnicalRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/technical-informations`)

export const patchHangarProductTechnicalRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/hangar-variant/${variantId}/technical-informations`, payload)

export const patchParkingProductTechnicalRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/parking-variant/${variantId}/technical-informations`, payload)

export const patchOutsideRampsProductTechnicalRequest = (productId, variantId, payload) => globalApi.patch(`${END_POINT}/${productId}/outside-ramps-variant/${variantId}/technical-informations`, payload)

// SLICES

export const fetchHangarProductsliceRequest = (productId, sliceId) => globalApi.get(`${END_POINT}/${productId}/hangar-variant-slice/${sliceId}`)

export const patchHangarProductSliceRequest = (productId, slice) => globalApi.patch(`${END_POINT}/${productId}/hangar-variant-slice/${slice.id}`, omit(slice, 'id'))

// PRICE MANAGEMENT

export const fetchHangarProductPriceRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/price-management`)

export const patchHangarProductPriceRequest = (productId, variantId, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/price-management`, product)

// CALENDAR

export const fetchHangarProductPriceCalendarRequest = (queryParams, productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar`, { params: queryParams })

export const fetchHangarProductPriceCalendarByIdRequest = (productId, variantId, rangeId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar/${rangeId}`)

export const postHangarProductPriceCalendarRequest = (productId, variantId, product) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar`, product)

export const patchHangarProductPriceCalendarRequest = (productId, variantId, range) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar/${range.id}`, omit(range, 'id'))

export const deleteHangarProductPriceCalendarRequest = (productId, variantId, rangeId) => globalApi.delete(`${END_POINT}/${productId}/variant/${variantId}/price-management/calendar/${rangeId}`)

// NEGOTIATED RATES

export const fetchHangarProductNegotiatedRatesRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`, { params: queryParams })

export const postHangarProductNegotiatedRateRequest = ({ productId, variantId }, product) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`, product)

export const patchHangarProductNegotiatedRateRequest = ({ productId, variantId, negotiatedRateId }, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate/${negotiatedRateId}`, product)

export const fetchHangarProductNegotiatedRateByIdRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`)

export const deleteHangarProductNegotiatedRateByIdRequest = (productId, variantId, negotiatedRateId) => fireDeleteAlert().then(isConfirmed => isConfirmed
  && globalApi.delete(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate/${negotiatedRateId}`))

// CANCELLATION CONDITIONS

// ? Never used
// export const fetchHangarProductCancellationConditionsRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/cancellation-conditions`)

// ? Never used
// export const patchHangarProductCancellationConditionsRequest = (productId, variantId, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/cancellation-conditions`, product)
