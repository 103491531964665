/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */

import { omit, pick, pickBy } from 'lodash'

import {
  fetchProductRequest,
  patchProductRequest,
} from '@/request/globalApi/requests/productRequests'

import {
  fetchFuelProductPlainRequest,
  patchFuelProductPlainRequest,
  postFuelProductPriceRequest,
  patchFuelProductPriceRequest,
  fetchFuelProductPriceRequest,
  deleteFuelProductPriceRequest,
  fetchFuelProductCertificateRequest,
  patchFuelProductCertificateRequest,
  fetchFuelProductNegotiatedRatesRequest,
  fetchFuelProductNegotiatedRateByIdRequest,
  deleteFuelProductNegotiatedRateByIdRequest,
  postFuelProductNegotiatedRateRequest,
  patchFuelProductNegotiatedRateRequest,
  // fetchFuelProductCancellationConditionsRequest,
  // patchFuelProductCancellationConditionsRequest,
  postFuelProductImportCSVRequest,
  postFuelProductUploadCSVRequest,
} from '@/request/globalApi/requests/productFuelRequests'
import store from '@/store/store'
import BaseModel from './_Base'

/**
 * Product model
 * @link https://vuemc.io/#basic-usage
 * @link https://vuemc.io/#model-data-access-active ( .sync(), .reset() etc )
 * @example use in /src/views/product/ProductWizard.vue
 */
class ProductService extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      name: {
        // TODO: dynamic lang init
        fr: '',
        en: '',
      },
      baseReference: '',
      typology: 'fuel',
      state: '',
      timezone: '',
      active: false,
      visible: true,
      defaultLang: {
        code: store.state.auth.defaultLang,
      },
      mainCategory: {
        id: null,
        name: {
          // TODO: dynamic lang init
          fr: '',
          en: '',
        },
      },
      mainVariant: {
        id: null,
        attrs: [],
        fuelType: { id: null },
        // TODO: dynamic lang init
        description: { en: '', fr: '' },
        visibilityEndAt: '',
        visibilityStartAt: '',
        visible: true,
        importUrl: null,
        negotiatedRates: [],
        fuelCertificateOption: {},
        vatRate: 0,
      },
      file: null,
      fuelVariantPrices: [],
      conditions: [],
      negotiatedRates: [],
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      name: Object,
      baseReference: String,
      typology: String,
      state: String,
      timezone: String,
      active: Boolean,
      defaultLang: Object,
      mainCategory: Object,
      mainVariant: Object,
      conditions: conditions => conditions || [],
      negotiatedRates: negotiatedRates => negotiatedRates || [],
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
   * GET /product/:id
   */
  async fetch(id = this.id) {
    await this.checkArgs(id)

    await fetchProductRequest(id).then(({ data }) => {
      const prod = {
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.product.mainVariant },
      }
      this.set(prod)
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit a product -> PATCH /product/:id
   */
  async patch(productId = this.id) {
    const payload = {
      ...pick(this, 'name', 'visible', 'baseReference', 'timezone'),
      mainCategoryId: this.mainCategory.id,
      defaultLangCode: this.defaultLang.code,
    }
    await patchProductRequest(productId, payload)
  }

  /**
   * GET /fuel-product/:id/additional-informations/:variantId
   */
  async fetchPlain(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchFuelProductPlainRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: {
          ...omit(data.variant, 'variantAttributes'),
          attrs: data.variant.variantAttributes.map(attr => ({
            ...attr,
            type: attr.hasOwnProperty('selectValue')
              ? 'select'
              : attr.hasOwnProperty('choiceValues')
                ? 'choice'
                : attr.hasOwnProperty('inputValues')
                  ? 'input'
                  : undefined,
          })),
          // ? Trick api, it return an array if no description, or object if has got description
          description: data.variant.description.length !== undefined
            ? {}
            : data.variant.description,
        },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * Edit the mainVariant of product -> PATCH /product/:id/main-information/:variant_id
   */
  async patchPlain(productId = this.id, variantId = this.mainVariant.id) {
    const payload = {
      description: pickBy(this.mainVariant.description, lang => !!lang),
      visibilityStartAt: this.mainVariant.visibilityStartAt,
      visibilityEndAt: this.mainVariant.visibilityEndAt,
      fuelTypeId: this.mainVariant.fuelType.id,
      variantAttributes: this.mainVariant.attrs.map(attr => {
        const values = attr.type === 'select'
          ? { selectValueId: attr.selectValue.id }
          : attr.type === 'choice'
            ? { choiceValueIds: attr.choiceValues.map(choice => choice.id) }
            : { inputValues: attr.inputValues }
        return {
          id: attr.id,
          attributeId: attr.attribute.id,
          type: attr.type,
          ...values,
        }
      }),
    }
    await patchFuelProductPlainRequest(
      { productId, variantId },
      payload,
    )
    this.sync()
  }

  /**
   * POST /fuel-product/:id/variant/:variantId/price/import
   */
  async postImportCSV(productId = this.id, variantId = this.mainVariant.id) {
    const payload = { url: this.mainVariant.importUrl }
    let action = 'post'
    if (this._reference.mainVariant.importUrl) {
      if (payload.url) action = 'patch'
      else action = 'delete'
    }
    await postFuelProductImportCSVRequest({ productId, variantId }, payload, action)
    this.sync()
  }

  /**
   * POST /fuel-product/:id/variant/:variantId/price/upload
   */
  async postUploadCSV(productId = this.id, variantId = this.mainVariant.id) {
    const payload = { temporaryFileId: this.file }
    await postFuelProductUploadCSVRequest({ productId, variantId }, payload)
  }

  /**
   * GET /fuel-product/:id/variant/:variantId/price
   */
  async fetchPrice(params = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchFuelProductPriceRequest(id, variantId, params).then(({ data }) => {
      this.set({
        ...data.product,
        fuelVariantPrices: data.fuelVariantPrices,
        mainVariant: { ...this.mainVariant, ...data.variant },
      })
      this.sync()
      return pick(data, 'firstPage', 'lastPage', 'nextPage', 'previousPage', 'totalItems')
    }).catch(this.catchError)
  }

  /**
   * PATCH /fuel-product/:id/variant/:variantId/:fuelVariantPriceId
   */
  async patchPrice(formData = {}, productId = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(productId, variantId)
    const payload = {
      ...formData,
      countryCode: formData.country.code,
    }
    await patchFuelProductPriceRequest({ productId, variantId }, payload)
    this.sync()
  }

  /**
   * POST /product/:id/variant/:variantId/price
   */
  async postPrice(formData = {}, productId = this.id, variantId = this.mainVariant.id) {
    const payload = {
      ...omit(formData, 'country'),
      countryCode: formData.country.code,
    }
    await postFuelProductPriceRequest({ productId, variantId }, payload)
    // TODO this.set(formdata)
  }

  /**
   * DELETE /fuel-product/:id/variant/:variantId/price/:priceId
   */
  async deletePrice(priceId, id = this.id, variantId = this.mainVariant.id) {
    await deleteFuelProductPriceRequest(id, variantId, priceId)
  }

  /**
   * GET /fuel-product/:id/fuel-certificate/:variantId
   */
  async fetchCertificate(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchFuelProductCertificateRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * PATCH /fuel-product/:id/fuel-certificate/:variantId/:fuelCretificateId
   */
  async patchCertificate(productId = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(productId, variantId)
    await patchFuelProductCertificateRequest(
      { productId, variantId },
      omit(this.mainVariant.fuelCertificateOption, 'id'),
    )
    this.sync()
  }

  /**
   * GET /fuel-product/:id/variant/:variantId/negotiated-rate
   * @returns {Object} pagination
   */
  async fetchNegotiatedRates(params = {}, id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchFuelProductNegotiatedRatesRequest(id, variantId, params).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
        negotiatedRates: data.items,
      })
      this.sync()
      return data
    }).catch(this.catchError)
  }

  /**
   * GET /fuel-product/:id/negotiated-rate/:variantId/:negociatedRateId
   */
  async fetchNegotiatedRateById(id = this.id, variantId = this.mainVariant.id) {
    await this.checkArgs(id, variantId)

    return fetchFuelProductNegotiatedRateByIdRequest(id, variantId).then(({ data }) => {
      this.set({
        ...data.product,
        mainVariant: { ...this.mainVariant, ...data.variant },
      })
      this.sync()
    }).catch(this.catchError)
  }

  /**
   * POST /fuel-product/:id/variant/:variant_id/negotiated-rate
   */
  async postNegotiatedRate(payload, productId = this.id, variantId = this.mainVariant.id) {
    postFuelProductNegotiatedRateRequest({ productId, variantId }, payload)
  }

  /**
   * PATCH /fuel-product/:id/variant/:variantId/negotiated-rate/:negotiatedRate
   */
  async patchNegotiatedRate(negotiatedRateId, payload, productId = this.id, variantId = this.mainVariant.id) {
    return patchFuelProductNegotiatedRateRequest({ productId, variantId, negotiatedRateId }, payload)
  }

  /**
   * DELETE /fuel-product/:id/variant/:variantId/negotiated-rate/:negociatedRateId
   */
  async deleteNegotiatedRateById(negotiatedRateId, id = this.id, variantId = this.mainVariant.id) {
    await deleteFuelProductNegotiatedRateByIdRequest(id, variantId, negotiatedRateId)
  }

  /**
   * GET /fuel-product/:id/price-management/:variantId
   * ? Never used
   */
  // async fetchCancellationConditions(id = this.id, variantId = this.mainVariant.id) {
  //   await this.checkArgs(id, variantId)

  //   return fetchFuelProductCancellationConditionsRequest(id, variantId).then(({ data }) => {
  //     this.set({
  //       ...data.product,
  //       conditions: data.items,
  //       mainVariant: { ...this.mainVariant, ...data.variant },
  //     })
  //     this.sync()
  //   }).catch(this.catchError)
  // }

  /**
   * Cancellation conditions of product -> PATCH /fuel-product/:id/cancellation-conditions/:variant_id
   * ? Never used
   */
  // async patchCancellationConditions(productId = this.id, variantId = this.mainVariant.id) {
  //   const payload = {
  //     customConditionEnabled: this.mainVariant.customCancellationPolicyConditionEnabled,
  //     customConditions: this.conditions,
  //     deletedCustomConditionIds: differenceWith(
  //       this._reference.conditions,
  //       this.conditions,
  //       isEqual,
  //     ).map(condition => condition.id),
  //     cancellationPolicyId: this.mainVariant.cancellationPolicy
  //       ? this.mainVariant.cancellationPolicy.id
  //       : null,
  //   }
  //   await patchFuelProductCancellationConditionsRequest({ productId, variantId }, payload)
  //   this.sync()
  // }
}

export default ProductService
