import axios from 'axios'
import { omit } from 'lodash'
import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
const END_POINT = 'api/fuel-product'

// --------------------
//    API Requests
// --------------------

// TODO: create instance for download
const token = `Bearer ${localStorage.getItem('token')}` || ''
export const downloadFuelPriceCSV = () => axios.get(`${process.env.VUE_APP_API_BASE_URL}api/export/fuel-price`, {
  headers: { Accept: 'text/csv', 'Content-Type': 'text/csv', Authorization: token },
}).then(response => {
  const blob = new Blob(
    [response.data],
    { type: 'text/csv' },
  )
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'export.csv' // TODO dynamic name when instance created
  link.click()
})

// ADDITIONAL INFORMATIONS

export const fetchFuelProductPlainRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/additional-informations/${variantId}`)

export const patchFuelProductPlainRequest = ({ productId, variantId }, product, message) => globalApi.patch(`${END_POINT}/${productId}/additional-informations/${variantId}`, product, { message })

// NEGOTIATED RATES

export const fetchFuelProductNegotiatedRatesRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/fuel-variant/${variantId}/negotiated-rate`, { params: queryParams })

export const postFuelProductNegotiatedRateRequest = ({ productId, variantId }, product) => globalApi.post(`${END_POINT}/${productId}/fuel-variant/${variantId}/negotiated-rate`, product)

export const patchFuelProductNegotiatedRateRequest = ({ productId, variantId, negotiatedRateId }, product) => globalApi.patch(`${END_POINT}/${productId}/fuel-variant/${variantId}/negotiated-rate/${negotiatedRateId}`, product)

export const fetchFuelProductNegotiatedRateByIdRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/fuel-variant/${variantId}/negotiated-rate`)

export const deleteFuelProductNegotiatedRateByIdRequest = (productId, variantId, negotiatedRateId) => fireDeleteAlert().then(isConfirmed => isConfirmed
  && globalApi.delete(`${END_POINT}/${productId}/fuel-variant/${variantId}/negotiated-rate/${negotiatedRateId}`))

// CSV

export const postFuelProductImportCSVRequest = ({ productId, variantId }, payload, action) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/price/import`, payload, { action })

export const postFuelProductUploadCSVRequest = ({ productId, variantId }, payload) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/price/upload`, payload)

// PRICE

export const fetchFuelProductPriceRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/price`, { params: queryParams })

export const postFuelProductPriceRequest = ({ productId, variantId }, product) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/price`, product)

export const patchFuelProductPriceRequest = ({ productId, variantId }, price) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/price/${price.id}`, omit(price, 'id'))

export const deleteFuelProductPriceRequest = (productId, variantId, priceId) => globalApi.delete(`${END_POINT}/${productId}/variant/${variantId}/price/${priceId}`)

// FUEL CERTIFICATE

export const fetchFuelProductCertificateRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/fuel-certificate/${variantId}`, { params: queryParams })

export const patchFuelProductCertificateRequest = ({ productId, variantId }, certificate) => globalApi.patch(`${END_POINT}/${productId}/fuel-certificate/${variantId}`, certificate)

// CANCELLATION CONDITIONS

// ? Never used
// export const fetchFuelProductCancellationConditionsRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/fuel-variant/${variantId}/cancellation-conditions`)

// ? Never used
// export const patchFuelProductCancellationConditionsRequest = (productId, variantId, product) => globalApi.patch(`${END_POINT}/${productId}/fuel-variant/${variantId}/cancellation-conditions`, product)
